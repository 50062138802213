<template>
  <svg width="54px" height="42px" viewBox="0 0 54 42" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
    <title>platform</title>
    <g id="Page-1" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
      <g id="Artboard" transform="translate(-492.000000, -90.000000)">
        <g id="platform" transform="translate(492.000000, 91.000000)">
          <g id="Group-3" stroke="#D85B15">
            <rect id="Rectangle" stroke-width="2" x="5" y="4" width="15" height="24"></rect>
            <line x1="6" y1="4" x2="47" y2="4" id="Line" stroke-width="2" stroke-linecap="square"></line>
            <path d="M30,28 L30,32 L4.12555096,32 C3.66086266,32 3.23587251,31.8047908 2.85911551,31.5241994 C2.32566213,31.1269079 1.88441486,30.5544574 1.56176703,29.9592909 C1.20006545,29.2920846 0.973027155,28.5898167 1.0020166,28.0662174 L1.0020166,28.0662174 L30,28 Z M52.8997443,28 C53.0583633,28.846822 52.8940899,29.5605459 52.539837,30.214012 C52.2590003,30.7320524 51.8511729,31.217064 51.3077618,31.5511952 C50.8535986,31.8304498 50.3045626,32 49.6630914,32 L49.6630914,32 L46,32 L46,28 Z" id="Rectangle-Copy-31" stroke-width="2"></path>
            <path d="M31,28 L5,28 L5,3 C5,1.34314575 6.34314575,0 8,0 L46,0 C47.6568542,0 49,1.34314575 49,3 L49,28 L49,28 L45,28" id="Path" stroke-width="2"></path>
            <circle id="Oval" cx="9" cy="9" r="1"></circle>
            <circle id="Oval-Copy-3" cx="9" cy="12" r="1"></circle>
            <circle id="Oval-Copy-4" cx="9" cy="15" r="1"></circle>
            <circle id="Oval-Copy-5" cx="9" cy="18" r="1"></circle>
            <rect id="Rectangle" x="11.5" y="8.5" width="5" height="1" rx="0.5"></rect>
            <rect id="Rectangle-Copy-22" x="23.5" y="8.5" width="15" height="1" rx="0.5"></rect>
            <rect id="Rectangle-Copy-17" x="11.5" y="11.5" width="3" height="1" rx="0.5"></rect>
            <rect id="Rectangle-Copy-23" x="23.5" y="11.5" width="4" height="1" rx="0.5"></rect>
            <rect id="Rectangle-Copy-24" x="23.5" y="14.5" width="3" height="1" rx="0.5"></rect>
            <rect id="Rectangle-Copy-25" x="23.5" y="17.5" width="2" height="1" rx="0.5"></rect>
            <rect id="Rectangle-Copy-21" x="11.5" y="17.5" width="3" height="1" rx="0.5"></rect>
            <rect id="Rectangle-Copy-20" x="11.5" y="14.5" width="5" height="1" rx="0.5"></rect>
          </g>
          <g id="phone" transform="translate(29.000000, 13.000000)">
            <rect id="Rectangle-Copy-26" stroke="#D85B15" x="8.5" y="5.5" width="5" height="1" rx="0.5"></rect>
            <rect id="Rectangle-Copy-29" stroke="#D85B15" x="8.5" y="9.5" width="5" height="1" rx="0.5"></rect>
            <rect id="Rectangle-Copy-30" stroke="#D85B15" x="8.5" y="13.5" width="5" height="1" rx="0.5"></rect>
            <circle id="Oval" fill="#D85B15" cx="5" cy="6" r="1"></circle>
            <circle id="Oval-Copy-6" fill="#D85B15" cx="5" cy="10" r="1"></circle>
            <circle id="Oval-Copy-7" fill="#D85B15" cx="5" cy="14" r="1"></circle>
            <rect id="Rectangle" stroke="#D85B15" stroke-width="2" x="1" y="1" width="16" height="26" rx="2"></rect>
            <circle id="Oval" fill="#D85B15" cx="9" cy="24" r="1"></circle>
          </g>
          <rect id="Rectangle" stroke="#D85B15" fill="#D85B15" x="31.5" y="34.5" width="13" height="1"></rect>
        </g>
      </g>
    </g>
  </svg>
</template>

<script>
export default {
  name: 'PlatformIcon',
}
</script>
