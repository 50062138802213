<template>
  <svg width="88" height="46" viewBox="0 0 88 46" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clip-path="url(#clip0_548_2861)">
      <mask id="mask0_548_2861" style="mask-type:luminance" maskUnits="userSpaceOnUse" x="0" y="0" width="88" height="46">
        <path d="M88 0H0V46H88V0Z" fill="white"/>
      </mask>
      <g mask="url(#mask0_548_2861)">
        <path d="M44.6538 20.0384C49.4332 20.0384 53.3077 16.1639 53.3077 11.3846C53.3077 6.60517 49.4332 2.73071 44.6538 2.73071C39.8745 2.73071 36 6.60517 36 11.3846C36 16.1639 39.8745 20.0384 44.6538 20.0384Z" stroke="#D85B15" stroke-width="4"/>
        <path d="M12.1923 22.5C15.0599 22.5 17.3846 20.1753 17.3846 17.3077C17.3846 14.44 15.0599 12.1154 12.1923 12.1154C9.32468 12.1154 7 14.44 7 17.3077C7 20.1753 9.32468 22.5 12.1923 22.5Z" stroke="#D85B15" stroke-width="4"/>
        <path d="M76.1923 22.5C79.0599 22.5 81.3846 20.1753 81.3846 17.3077C81.3846 14.44 79.0599 12.1154 76.1923 12.1154C73.3247 12.1154 71 14.44 71 17.3077C71 20.1753 73.3247 22.5 76.1923 22.5Z" stroke="#D85B15" stroke-width="4"/>
      </g>
      <path d="M9.21098 29H17.0783C18.9157 29 20.0249 31.0332 19.0304 32.5782C17.6888 34.6621 17.6888 37.3379 19.0304 39.4218C20.0249 40.9668 18.9157 43 17.0784 43H7.14168C4.93534 43 2.99285 41.5461 2.37091 39.4293C2.15877 38.7072 2.11329 37.9465 2.23789 37.2043L2.68881 34.5185C3.22359 31.3331 5.98104 29 9.21098 29Z" stroke="#D85B15" stroke-width="4"/>
      <path d="M79.789 29H71.9217C70.0843 29 68.9751 31.0332 69.9696 32.5782C71.3112 34.6621 71.3112 37.3379 69.9696 39.4218C68.9751 40.9668 70.0843 43 71.9216 43H81.8583C84.0647 43 86.0072 41.5461 86.6291 39.4293C86.8412 38.7072 86.8867 37.9465 86.7621 37.2043L86.3112 34.5185C85.7764 31.3331 83.019 29 79.789 29Z" stroke="#D85B15" stroke-width="4"/>
      <path d="M37.5099 26H51.4635C57.0878 26 61.8562 30.1356 62.6516 35.7034L62.7862 36.6455C62.9041 37.4707 62.8607 38.3108 62.6586 39.1195C61.9415 41.9878 59.3643 44 56.4077 44H33.0057C30.2269 44 27.7396 42.2763 26.7639 39.6745C26.3163 38.4807 26.2203 37.1837 26.4875 35.9371L26.7535 34.6957C27.8403 29.6236 32.3227 26 37.5099 26Z" stroke="#D85B15" stroke-width="4"/>
    </g>
    <defs>
      <clipPath id="clip0_548_2861">
        <rect width="88" height="46" fill="white"/>
      </clipPath>
    </defs>
  </svg>
</template>

<script>
export default {
  name: 'CrmIcon',
}
</script>
