import Vue from 'vue';
import VueRouter from 'vue-router';

Vue.use(VueRouter);

const routes = [
  {
    path: '/',
    name: 'Home',
    component: () => import(/* webpackChunkName: "about" */ './components/YabbrBrochure.vue'),

  },
  {
    path: '/pricing',
    name: 'PricingPage',
    component: () => import(/* webpackChunkName: "about" */ './components/PricingPage.vue'),
  },

];

const router = new VueRouter({
  mode: 'history',
  routes,
  scrollBehavior () {
    return { x: 0, y: 0 }
  }
});

export default router;
