<template>
  <svg width="64" height="41" viewBox="0 0 64 41" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clip-path="url(#clip0_549_2900)">
      <path d="M36 2H28C25.7909 2 24 3.79086 24 6V8C24 10.2091 25.7909 12 28 12H36C38.2091 12 40 10.2091 40 8V6C40 3.79086 38.2091 2 36 2Z" stroke="#D85B15" stroke-width="3"/>
      <path d="M14 29H6C3.79086 29 2 30.7909 2 33V35C2 37.2091 3.79086 39 6 39H14C16.2091 39 18 37.2091 18 35V33C18 30.7909 16.2091 29 14 29Z" stroke="#D85B15" stroke-width="3"/>
      <path d="M36 29H28C25.7909 29 24 30.7909 24 33V35C24 37.2091 25.7909 39 28 39H36C38.2091 39 40 37.2091 40 35V33C40 30.7909 38.2091 29 36 29Z" stroke="#D85B15" stroke-width="3"/>
      <path d="M58 29H50C47.7909 29 46 30.7909 46 33V35C46 37.2091 47.7909 39 50 39H58C60.2091 39 62 37.2091 62 35V33C62 30.7909 60.2091 29 58 29Z" stroke="#D85B15" stroke-width="3"/>
      <path d="M33 15H31V20H33V15Z" fill="#D85B15"/>
      <path d="M11 20H9V26H11V20Z" fill="#D85B15"/>
      <path d="M55 20H53V26H55V20Z" fill="#D85B15"/>
      <path d="M33 20H31V26H33V20Z" fill="#D85B15"/>
      <path d="M55 20H9V22H55V20Z" fill="#D85B15"/>
    </g>
    <defs>
      <clipPath id="clip0_549_2900">
        <rect width="64" height="41" fill="white"/>
      </clipPath>
    </defs>
  </svg>
</template>

<script>
export default {
  name: 'WorkflowIcon',
}
</script>
