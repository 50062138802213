import Vue from 'vue';
import Vuetify from 'vuetify/lib/framework';
import ChatBotIcon from './icons/ChatBotIcon.vue';
import ContactToolsIcon from './icons/ContactToolsIcon.vue';
import PlatformIcon from './icons/PlatformIcon.vue';
import WorkflowIcon from './icons/WorkflowIcon.vue';
import LiveChatIcon from './icons/LiveChatIcon.vue';
import CrmIcon from './icons/CrmIcon.vue';

Vue.use(Vuetify);

export default new Vuetify({
    icons: {
        values: {
            chatBot: {
                component: ChatBotIcon,
            },
            contactTools: {
                component: ContactToolsIcon,
            },
            platform: {
                component: PlatformIcon,
            },
            workflow: {
                component: WorkflowIcon,
            },
            liveChat: {
                component: LiveChatIcon,
            },
            crm: {
                component: CrmIcon,
            },
        }
    }
});
