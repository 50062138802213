<template>
  <svg width="42px" height="42px" viewBox="0 0 42 42" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
    <title>chatbot</title>
    <g id="Page-1" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
      <g id="Artboard" transform="translate(-771.000000, -352.000000)" stroke="#D85B15">
        <g id="chatbot" transform="translate(771.000000, 352.000000)">
          <rect id="Rectangle" fill="#D85B15" x="12.5" y="5.5" width="1" height="3"></rect>
          <rect id="Rectangle-Copy-2" stroke-width="2" x="1" y="18" width="4" height="8" rx="2"></rect>
          <rect id="Rectangle-Copy-14" stroke-width="2" x="37" y="18" width="4" height="8" rx="2"></rect>
          <rect id="Rectangle-Copy" fill="#D85B15" x="28.5" y="5.5" width="1" height="3"></rect>
          <path d="M13,18 C13.3333333,16.6666667 14,16 15,16 C16.5,16 17,17.4779847 17,18" id="Path-4" stroke-width="2" stroke-linecap="round"></path>
          <path d="M25,18 C25.3333333,16.6666667 26,16 27,16 C28.5,16 29,17.4779847 29,18" id="Path-4-Copy" stroke-width="2" stroke-linecap="round"></path>
          <circle id="Oval" stroke-width="2" cx="29" cy="3" r="2"></circle>
          <circle id="Oval-Copy" stroke-width="2" cx="13" cy="3" r="2"></circle>
          <path d="M33,9 C34.1045695,9 35.1045695,9.44771525 35.8284271,10.1715729 C36.5522847,10.8954305 37,11.8954305 37,13 L37,13 L37,31 C37,32.1045695 36.5522847,33.1045695 35.8284271,33.8284271 C35.1045695,34.5522847 34.1045695,35 33,35 L33,35 L25.2039404,35 L25.8339042,39.4083974 C25.8729567,39.6817644 25.7975081,39.9450809 25.6439547,40.1498188 C25.4904013,40.3545566 25.2587431,40.5007158 24.9853761,40.5397683 C24.7252232,40.576933 24.4608965,40.5101974 24.2496479,40.3540572 L24.2496479,40.3540572 L17.007511,35 L9,35 C7.8954305,35 6.8954305,34.5522847 6.17157288,33.8284271 C5.44771525,33.1045695 5,32.1045695 5,31 L5,31 L5,13 C5,11.8954305 5.44771525,10.8954305 6.17157288,10.1715729 C6.8954305,9.44771525 7.8954305,9 9,9 L9,9 Z" id="Rectangle-Copy-10" stroke-width="2" transform="translate(21.000000, 24.774913) scale(-1, 1) translate(-21.000000, -24.774913) "></path>
          <path d="M14,26 C16,27 18,28 21,28 C24,28 26,27 28,26" id="Path-2" stroke-width="2" stroke-linecap="round"></path>
        </g>
      </g>
    </g>
  </svg>
</template>

<script>
export default {
  name: 'ChatBotIcon',
}
</script>
