<template>
  <v-app>
    <Navigation />
    <v-main>
      <router-view/>
    </v-main>
    <PageFooter />
  </v-app>
</template>

<script>
import Navigation from './components/Navigation.vue'
import PageFooter from './components/PageFooter.vue'

export default {
  name: 'App',
  components: {
    Navigation,
    PageFooter,
  },
  mounted() {
    (function(y,a,b,r){
      if (y.yabbr) return;
      r = a.createElement("script");
      r.async = 1;
      r.src = "https://yabbr.chat/loader.js";
      a.body.appendChild(r);
      r = y.yabbr = function(c,d){ r._[c] = d; };
      r._ = { key: b };
    })(window, document, "3ce6f8785d52edec32ff1d3c4a27e1b5a76e4e2c43802622ae444849b1fa39ca");
  },
};
</script>
<style lang="scss">
.btn-primary {
  text-transform: none !important;
  background-color: #1582d8 !important;
  // font-weight: 600;
  font-size: $font-size-root !important;
  letter-spacing: 0px;

  &--outline {
    text-transform: none !important;
    background-color: #fff !important;
    border-color:  #1582d8 !important;
    color:   #1582d8 !important;
    // font-weight: 600 !important;
    font-size: $font-size-root !important;
    letter-spacing: 0px;
  }

  &--outline-large {
    text-transform: none !important;
    background-color: #fff !important;
    border-color:  #1582d8 !important;
    color:   #1582d8 !important;
    font-weight: 600 !important;
    font-size: $font-size-root !important;
    padding-left: 4rem !important;
    padding-right: 4rem !important;
  }

  &--mobile {
    width: 90% !important;
  }
}

// .container-pad px-10 {
//     padding-left: 2rem;
//     padding-right: 2rem;
//
//     @include atSmall {
//       padding-left: 6rem !important;
//       padding-right: 6rem !important;
//     }
//
//     @include atExtraLarge {
//         padding-left: 20rem;
//         padding-right: 20rem;
//     }
// }

// .container-left {
//   padding-left: 8.5rem;
//   @include atExtraLarge {
//     padding-left: 20rem;
//   }
// }

.section {
  padding-top: 2rem;
  padding-bottom: 2rem;

  @include atLarge {
    padding-top: 6rem;
    padding-bottom: 6rem;
  }

  &__header-container {
    // margin-bottom: 3.1rem;

    @include atSmall {
      margin-bottom: 0;
    }

    @include atMedium {
      margin-bottom: 0;
    }
  }

  &__header-title {
    margin-bottom: 1.87rem;
  }
}
</style>
