<template>
  <svg width="39" height="34" viewBox="0 0 39 34" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M5.31256 25.9639L5.45208 25.4131L5.05028 25.0113C2.48087 22.4419 1.02615 19.2814 1.02615 15.7858C1.02615 7.88393 9.01355 1.01855 19.4359 1.01855H19.4528C29.7976 1.01855 37.8626 7.80513 37.8626 15.7858C37.8626 23.7666 29.7976 30.5531 19.4528 30.5531C16.6047 30.5531 13.9887 30.0519 11.5999 29.1803L11.0592 28.983L10.617 29.3515C9.01346 30.6878 5.77294 32.7074 1.50564 32.9561C1.82662 32.5607 2.25621 32.0109 2.71606 31.3465C3.66499 29.9753 4.78135 28.0613 5.31256 25.9639Z" stroke="#D85B15" stroke-width="2"/>
    <path d="M11.3923 19.479C12.6682 19.479 13.7026 18.4446 13.7026 17.1687C13.7026 15.8927 12.6682 14.8584 11.3923 14.8584C10.1164 14.8584 9.08203 15.8927 9.08203 17.1687C9.08203 18.4446 10.1164 19.479 11.3923 19.479Z" fill="#D85B15"/>
    <path d="M19.0823 19.479C20.3582 19.479 21.3925 18.4446 21.3925 17.1687C21.3925 15.8927 20.3582 14.8584 19.0823 14.8584C17.8063 14.8584 16.772 15.8927 16.772 17.1687C16.772 18.4446 17.8063 19.479 19.0823 19.479Z" fill="#D85B15"/>
    <path d="M26.7883 19.479C28.0643 19.479 29.0986 18.4446 29.0986 17.1687C29.0986 15.8927 28.0643 14.8584 26.7883 14.8584C25.5124 14.8584 24.478 15.8927 24.478 17.1687C24.478 18.4446 25.5124 19.479 26.7883 19.479Z" fill="#D85B15"/>
  </svg>
</template>

<script>
export default {
  name: 'LiveChatIcon',
}
</script>
