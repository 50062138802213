<template>
  <div>
    <v-app-bar elevate-on-scroll color="white" app>
      <v-container class="px-10 pt-12 mb-3 mb-sm-4 mb-md-6" fluid>
        <v-row
          class="navigation"
          align="center"
          justify="space-between">
          <v-col
            md="2"
            col="12"
            class="navigation__logo d-flex align-center justify-start">
              <a href="/">
                <v-img
                src="../assets/img/yabbr-logo-primary.png"
                height="40"
                width="150"
                alt="Yabbr logo"
                contain />
              </a>
            </v-col>
            <v-col
              xl="6"
              lg="6"
              class="navigation__links d-xl-flex d-lg-flex d-md-none d-sm-none d-none justify-center align-center">
              <template v-for="(menu, menuIndex) in menuItems">
                <v-btn
                  :key="menuIndex"
                  :ripple="false"
                  plain
                  :to="menu.page"
                  class="pb-7 pt-7 mr-6 navigation__links___menuItem text-capitalize text-body-1"
                  v-if="!menu.hasOwnProperty('children')">
                  {{  menu.title }}
                </v-btn>
                <v-menu
                  v-if="menu.hasOwnProperty('children')"
                  :key="menuIndex"
                  transition="fade-transition"
                  max-width="320"
                  min-width="320"
                  class="mt-5"
                  :close-on-content-click="false"
                  offset-y
                  open-on-hover
                  open-on-focus
                  nudge-left="100"
                  nudge-top="0">
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      :ripple="false"
                      plain
                      class="pb-7 pt-7 mr-6 navigation__links___menuItem text-capitalize text-body-1"
                      v-bind="attrs"
                      :to="menu.page"
                      v-on="on">
                      {{  menu.title }}
                    </v-btn>
                  </template>
                  <v-expand-transition>
                    <v-list flat class="rounded-lg pa-4 navigation__links___menuItem___list">
                      <v-list-item-group
                        v-for="(childMenu, childMenuIndex) in menu.children"
                        :key="childMenuIndex"
                        :model="1">
                        <v-hover v-slot="{hover}" close-delay="70">
                          <v-list-item
                            :class="{ 'on-hover': hover }"
                            :href="childMenu.link"
                            :ripple="false">
                            <div class="d-flex flex-column align-center justify-center py-3">
                              <v-list-item-content class="pa-0 mt-2">
                                <v-list-item-title
                                  :class="hover ? 'text--primary' : 'blue-grey--text text--darken-2'"
                                  class="text-wrap font-weight-medium"
                                  v-text="childMenu.title" />
                                <v-list-item-subtitle
                                  class="blue-grey--text text-wrap"
                                  v-text="childMenu.description" />
                              </v-list-item-content>
                            </div>
                          </v-list-item>
                        </v-hover>
                      </v-list-item-group>
                    </v-list>
                  </v-expand-transition>
                </v-menu>
              </template>
            </v-col>
            <v-col xl="3" lg="3" md="6" sm="6" cols="2">
              <div class="d-xl-flex d-lg-flex d-md-flex d-sm-none d-none justify-end align-center">
                <v-btn
                  class="ma-2 btn-primary--outline rounded-lg"
                  outlined
                  depressed
                  :ripple="false"
                  @click="openChat">
                  Chat with us
                </v-btn>
                <v-btn
                  class="ma-2 white--text btn-primary rounded-lg"
                  depressed
                  :ripple="false"
                  href="https://go.yabbr.io/#/signup">
                  Sign up
                </v-btn>
                <v-btn
                  class="ma-2 btn-primary--outline rounded-lg"
                  depressed
                  :ripple="false"
                  href="https://go.yabbr.io/#/">
                  Log in
                </v-btn>
                <v-app-bar-nav-icon class="d-xl-none d-lg-none" @click="drawer = true" />
              </div>
              <div class="d-xl-none d-lg-none d-md-none d-sm-flex d-flex justify-end align-center">
                <v-app-bar-nav-icon large @click="drawer = true"></v-app-bar-nav-icon>
              </div>
          </v-col>
        </v-row>
      </v-container>
    </v-app-bar>
    <!-- mobile menu -->
    <div class="d-xl-none d-lg-flex d-md-flex d-sm-flex d-flex">
      <v-navigation-drawer
        class="navigation__drawer container pl-6 pr-6"
        v-model="drawer"
        temporary
        fixed
        right>
        <div class="d-flex justify-end pt-0 pb-2 pr-2">
          <v-btn
            large
            icon
            @click="drawer = false">
            <v-icon large>mdi-window-close</v-icon>
          </v-btn>
        </div>
        <v-list nav class="mb-4">
          <template v-for="(mobileMenuItem, mobileMenuItemIndex) in menuItems">
            <v-list-item-group
              v-model="group"
              :key="mobileMenuItemIndex"
              v-if="!mobileMenuItem.hasOwnProperty('children')">
              <v-list-item
                v-model="selection"
                :href="mobileMenuItem.page"
                :ripple="false"
                @click="drawer=false"
                class="py-1">
                <v-list-item-title>{{ mobileMenuItem.title }}</v-list-item-title>
              </v-list-item>
            </v-list-item-group>
            <v-list-group
              v-if="mobileMenuItem.hasOwnProperty('children')"
              :key="mobileMenuItemIndex"
              :value="false"
              :ripple="false">
              <template v-slot:activator>
                <v-list-item-content class="pa-0">
                  <v-list-item-title>{{ mobileMenuItem.title }}</v-list-item-title>
                </v-list-item-content>
              </template>
              <v-list-item
                v-for="(childMobileItem, childMobileItemIndex) in mobileMenuItem.children"
                :key="childMobileItemIndex"
                link
                class="ml-4"
                :href="childMobileItem.link">
                <v-list-item-content class="ma-0">
                  <v-list-item-title v-text="childMobileItem.title" class="submenu-title" />
                  <v-list-item-subtitle
                    class="submenu-subtitle blue-grey--text text--darken-2 text-wrap"
                    v-text="childMobileItem.description"/>
                </v-list-item-content>
              </v-list-item>
            </v-list-group>
          </template>
        </v-list>
        <v-row
          align="center"
          justify="center"
          class="pt-4">
          <v-btn
            class="btn-primary--outline btn-primary--mobile rounded-lg py-6 text-h6"
            outlined
            depressed
            :ripple="false"
            @click="openChat">
            Chat with us
          </v-btn>
        </v-row>
        <v-row
          align="center"
          justify="center"
          class="px-1 pt-4">
          <v-btn
            class="white--text btn-primary btn-primary--mobile rounded-lg py-6 text-h6"
            depressed
            :ripple="false"
            href="https://go.yabbr.io/#/signup">
            Sign up
          </v-btn>
        </v-row>
        <v-row
          align="center"
          justify="center"
          class="pt-4">
          <v-btn
            class="btn-primary--outline btn-primary--mobile rounded-lg py-6 text-h6"
            depressed
            :ripple="false">
            Log in
          </v-btn>
        </v-row>
      </v-navigation-drawer>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Navigation',
  data: () => ({
    drawer: false,
    group: null,
    selection: null,
    menuItems: [
      {
        icon: 'mdi-chat-processing-outline',
        title: 'Home',
        page: '/'
      },
      {
        icon: 'mdi-hand-coin-outline',
        title: 'Pricing',
        page: '/pricing'
      },
      

    ],
  }),
  methods: {
    openChat() {
      if (window.yabbr) {
        window.openYabbrWidget();

      } else {
        console.error('Yabbr is not loaded yet.');
      }
    }
  },
}
</script>

<style lang="scss">
.navigation {
  &__links___menuItem {
    position:relative;
    color: #091531 !important;
    font-weight: 500 !important;
  }

  // &__links___menuItem:after, &__links___menuItem[aria-expanded="false"]:after {
  //   content: '';
  //   width: 100%;
  //   height: 4px;
  //   background: white;
  //   opacity: 0;
  //   transition: opacity 0.5s ease 0s;
  // }
  //
  // &__links___menuItem:hover:after, &__links___menuItem[aria-expanded="true"]:after {
  //   content: '';
  //   width: 100%;
  //   height: 4px;
  //   background: #1582d8;
  //   position:absolute;
  //   bottom: 0;
  //   opacity: 1;
  //   display:block;
  //   transition: opacity 0.5s ease 0s;
  // }

  &__links___menuItem___list{
    .v-subheader {
      color: #091531 !important;
      letter-spacing: .1rem !important;
    }

    .v-list-item {
      &.on-hover {
        color: #1582d8 !important;
        // background: #F5F7FA !important;
      }

    }
  }

  &.v-list-item__title {
    // font-weight: 600 !important;
  }

  &__drawer {
    width: 100% !important;

    .v-navigation-drawer__content {
      height: 100% !important;
    }

    .v-list-item__title {
      font-size: 1.125rem;
      font-weight: 500 !important;
      line-height: 1.5 !important;

      &.submenu-title {
        font-size: 1rem;
      }
    }

    .submenu-subtitle {
        font-size: 0.875rem !important;
        margin-bottom: 10px;
    }


    .v-list-group__header__append-icon > i {
      font-size: 34px;
    }

    @include atMedium {
      width: 50% !important;
    }

    @include atLarge {
      width: 25% !important;
    }

    // .v-list-item__subtitle {
    //   font-weight: 400 !important;
    //   line-height: 1.5 !important;
    //   letter-spacing: 0 !important;
    // }
  }
}
</style>
