<template>
  <div class="page-footer">
    <v-container class="px-10 pt-7 pb-7" fluid>
      <v-row>
        <v-col class="text-center mt-8 mb-2">
          <div class="d-flex justify-center align-center">
            <p class="text-gray">L1/315 Brunswick Street<br /> Fortitude Valley QLD 4006</p>
            <a href="https://www.linkedin.com/company/yabbr/">
              <v-img
                class="social-icon ml-3"
                src="../assets/img/linkedin.png"
                alt="LinkedIn logo"
                contain />
            </a>
          </div>
          <p class="text-gray text-caption pt-1">© {{new Date().getFullYear()}} Yabbr. All rights reserved. <a href="./docs/Yabbr-Privacy-Policy-2019-v1.3.pdf" target="_blank">Privacy policy</a>
          </p>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
export default {
  name: 'PageFooter',
}
</script>

<style lang="scss">
.social-icon {
  width: 24px;
  height: 24px;
}
</style>
